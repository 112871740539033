// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2020-promo-page-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/2020-promo-page.js" /* webpackChunkName: "component---src-pages-2020-promo-page-js" */),
  "component---src-pages-blog-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-10-things-you-eventually-learn-about-seo-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/10-things-you-eventually-learn-about-seo.js" /* webpackChunkName: "component---src-pages-blog-10-things-you-eventually-learn-about-seo-js" */),
  "component---src-pages-blog-how-long-did-it-take-to-find-clients-startup-takeoff-time-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/how-long-did-it-take-to-find-clients-startup-takeoff-time.js" /* webpackChunkName: "component---src-pages-blog-how-long-did-it-take-to-find-clients-startup-takeoff-time-js" */),
  "component---src-pages-blog-how-startups-get-first-clients-amsterdam-netherlands-1-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/how-startups-get-first-clients-amsterdam-netherlands-1.js" /* webpackChunkName: "component---src-pages-blog-how-startups-get-first-clients-amsterdam-netherlands-1-js" */),
  "component---src-pages-blog-how-to-create-a-one-page-business-website-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/how-to-create-a-one-page-business-website.js" /* webpackChunkName: "component---src-pages-blog-how-to-create-a-one-page-business-website-js" */),
  "component---src-pages-blog-how-to-use-regex-improved-javascript-search-filter-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/how-to-use-regex-improved-javascript-search-filter.js" /* webpackChunkName: "component---src-pages-blog-how-to-use-regex-improved-javascript-search-filter-js" */),
  "component---src-pages-blog-new-css-features-2019-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/new-css-features-2019.js" /* webpackChunkName: "component---src-pages-blog-new-css-features-2019-js" */),
  "component---src-pages-blog-solve-real-problems-with-math-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/solve-real-problems-with-math.js" /* webpackChunkName: "component---src-pages-blog-solve-real-problems-with-math-js" */),
  "component---src-pages-blog-underrun-qt-december-2019-battle-update-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/underrun-qt-december-2019-battle-update.js" /* webpackChunkName: "component---src-pages-blog-underrun-qt-december-2019-battle-update-js" */),
  "component---src-pages-blog-website-builder-november-2019-update-notes-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/blog/website-builder-november-2019-update-notes.js" /* webpackChunkName: "component---src-pages-blog-website-builder-november-2019-update-notes-js" */),
  "component---src-pages-features-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nl-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/nl.js" /* webpackChunkName: "component---src-pages-nl-js" */),
  "component---src-pages-nl-blog-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/nl/blog.js" /* webpackChunkName: "component---src-pages-nl-blog-js" */),
  "component---src-pages-pricing-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/ec2-user/api/wtl-project-starter/build.35b3cb4f-7580-4ab2-8318-0cbe79ad44be/.cache/data.json")


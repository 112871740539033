export class VariablesService {
  constructor() {
    this.vars = {};
  }

  setVar(id, value) {
    this.vars[id] = value;
  }

  getVar(id) {
    return this.vars[id];
  }
}

export default new VariablesService();
